import { lazy } from 'react';
const Login = lazy(() => import('../Pages/Login'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const ViewService = lazy(() => import('../Pages/ViewService'));

const Route = [
  {
    title: 'Login',
    path: '/',
    component: <Login />,
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    title: 'Views Services',
    path: '/viewservices',
    component: <ViewService />,
  }

];

export default Route;