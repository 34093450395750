import { Suspense } from "react";
import "./App.css";
import "./Styles/main.css";
import { Route, Routes } from "react-router-dom";
import FallBackLoder from "./Components/FallbackLoder";
import SiteRoutes from "./utility/Routes";
import Protected from "./utility/Protected";
import config from "./config";
global.ftk = localStorage.getItem("appToken") ? localStorage.getItem("appToken") : undefined;
global.appUrl = config.BASE_URL;

function App() {
  return (
    <Suspense fallback={<FallBackLoder/>}>
      <Routes>
        {SiteRoutes &&
          SiteRoutes.map((route, i) => {
            return (
              <Route
                key={route.title}
                exact={route.path === "/" ? true : false}
                path={route.path}
                element={<Protected Componant={route.component} />}
              ></Route>
            );
          })}
      </Routes>
    </Suspense>
  );
}

export default App;
