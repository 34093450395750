import React from 'react'
import '../Styles/fallbackloder.css'

export default function FallbackLoder() {
  return (
    <>
      <div id="cartprogressdiv" style={{display:"visible"}}>
    <div id="cartInnerrProgressmaster" className="progressbarCss" style={{backgroundColor:"black", height:"100%", left: 0, opacity:0.2, overflow:'hidden', position:'fixed', textAlign:'center', top: 0, width:"100%", zIndex: 1000000}} ></div>
    <div className="lft cp" id="loadingmaster" style={{backgroundColor:'white', position:'fixed', zIndex: 10000, textAlign:'center', width: "45px", height:"37px", top:0, left:"-20px", borderRadius:"10px", marginTop:"20%", marginLeft:"50%", paddingTop:"5px"}} >
    <img src="https://cdn.fcglcdn.com/brainbees/images/m/loader_m.gif" alt='FallBack Loder' style={{width:"auto !important"}} />
    </div>
    </div>
    </>
  )
}